import { PAGE_UPDATE } from "./Navigation";

export default class FadeUpOnLoad {
  constructor() {
    this.setup = this.setup.bind(this);
    this.handleLoad = this.handleLoad.bind(this);
    window.addEventListener(PAGE_UPDATE, this.setup);
    this.setup();
  }
  setup() {
    this.els = Array.from(document.querySelectorAll(".js-FadeUpOnLoad"));
    this.els.forEach((el) => {
      if (el.complete) {
        this.addActiveClass(el);
      } else {
        el.addEventListener("load", this.handleLoad);
      }
    });
  }

  handleLoad(e) {
    this.addActiveClass(e.target);
  }

  addActiveClass(el) {
    el.classList.add("active");
  }
}
