import { PAGE_UPDATE } from "./Navigation";

export default class PhotographyLightbox {
  constructor() {
    this.setup = this.setup.bind(this);
    this.handleOpenLightbox = this.handleOpenLightbox.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleKeyUp = this.handleKeyUp.bind(this);
    this.goNext = this.goNext.bind(this);
    this.goPrev = this.goPrev.bind(this);
    this.handleLightboxChange = this.handleLightboxChange.bind(this);
    window.addEventListener(PAGE_UPDATE, this.setup);
    this.setup();
  }

  setup() {
    this.photographEls = Array.from(
      document.querySelectorAll(".js-PhotographyLightbox")
    );
    this.orderedPhotographEls = Array.from(
      document.querySelectorAll(".PhotographyGrid-mobile .PhotographyGrid-item")
    );
    this.overlayEl = document.querySelector(".js-ProjectOverlay");
    this.wrapperEl = document.querySelector(".PhotographWrapper");
    this.contentEl = document.querySelector(".PhotographyLightbox-content");
    this.nextBtnEl = document.querySelector(
      ".PhotographyLightbox-navButton.next"
    );
    this.prevBtnEl = document.querySelector(
      ".PhotographyLightbox-navButton.prev"
    );
    if (
      this.photographEls.length &&
      this.overlayEl &&
      this.orderedPhotographEls.length
    ) {
      this.photographEls.forEach((photographEl) => {
        photographEl.addEventListener("click", this.handleOpenLightbox);
      });
    }
  }

  handleOpenLightbox(e) {
    this.el = e.target;
    this.overlayEl.classList.add("active");
    this.overlayEl.addEventListener("click", this.handleClick);
    window.addEventListener("keyup", this.handleKeyUp);
    this.wrapperEl.innerHTML = this.el.dataset.content;
    if (this.el.dataset.brand && this.el.dataset.title) {
      this.contentEl.innerHTML = `${this.el.dataset.brand} — ${this.el.dataset.title}`;
    } else if (this.el.dataset.brand && !this.el.dataset.title) {
      this.contentEl.innerHTML = `${this.el.dataset.brand}`;
    } else if (!this.el.dataset.brand && this.el.dataset.title) {
      this.contentEl.innerHTML = `${this.el.dataset.title}`;
    } else {
      this.contentEl.innerHTML = "";
    }

    this.lightboxIndex = this.orderedPhotographEls.findIndex((el) => {
      return el.dataset.imageId === this.el.dataset.imageId;
    });
  }

  async handleLightboxChange() {
    this.el = this.orderedPhotographEls[this.lightboxIndex];
    this.wrapperEl.innerHTML = this.el.dataset.content;
    if (this.el.dataset.brand && this.el.dataset.title) {
      this.contentEl.innerHTML = `${this.el.dataset.brand} — ${this.el.dataset.title}`;
    } else if (this.el.dataset.brand && !this.el.dataset.title) {
      this.contentEl.innerHTML = `${this.el.dataset.brand}`;
    } else if (!this.el.dataset.brand && this.el.dataset.title) {
      this.contentEl.innerHTML = `${this.el.dataset.title}`;
    } else {
      this.contentEl.innerHTML = "";
    }
  }

  goNext() {
    if (this.lightboxIndex === this.orderedPhotographEls.length - 1) {
      this.lightboxIndex = 0;
    } else {
      this.lightboxIndex += 1;
    }
    this.handleLightboxChange();
  }

  goPrev() {
    if (this.lightboxIndex === 0) {
      this.lightboxIndex = this.orderedPhotographEls.length - 1;
    } else {
      this.lightboxIndex -= 1;
    }
    this.handleLightboxChange();
  }

  handleClick(e) {
    if (e.target.classList.contains("next")) {
      this.goNext();
    } else if (e.target.classList.contains("prev")) {
      this.goPrev();
    } else {
      this.overlayEl.classList.remove("active");
      window.removeEventListener("keyup", this.handleKeyUp);
    }
  }

  handleKeyUp(e) {
    if (e.key === "ArrowRight") {
      this.goNext();
    } else if (e.key === "ArrowLeft") {
      this.goPrev();
    }
  }
}
