export function isMobile() {
  const uaIsMobile = window.navigator.userAgent.match(
    /(iPhone|iPod|iPad|Android|webOS|BlackBerry|IEMobile|Opera Mini)/i
  );
  return "ontouchstart" in document.documentElement && uaIsMobile;
}

export function forceReflow(el) {
  getComputedStyle(el).opacity;
}

export async function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
