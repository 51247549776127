export default class InfiniteScroll {
  constructor() {
    this.setup = this.setup.bind(this);
    this.handleLoad = this.handleLoad.bind(this);
    this.setup();
  }

  setup() {
    this.els = Array.from(document.querySelectorAll(".js-InfiniteScroll"));

    this.observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("active");
          this.observer.unobserve(entry.target);
        }
      }),
        { threshold: 1 };
    });

    this.els.forEach((el) => {
      if (el.complete) {
        this.observer.observe(el);
      } else {
        el.addEventListener("load", this.handleLoad);
      }
    });
  }
  handleLoad(e) {
    this.observer.observe(e.target);
  }
}
