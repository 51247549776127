import { sleep } from "../modules/utils";
import { NAVIGATE } from "./Links";
import { PAGE_UPDATE } from "./Navigation";

const CONTENT_DELAY = 750;

export default class TextReveal {
  constructor() {
    this.setup = this.setup.bind(this);
    this.handleImageLoaded = this.handleImageLoaded.bind(this);
    this.handleMouseMove = this.handleMouseMove.bind(this);
    this.destroy = this.destroy.bind(this);
    window.addEventListener(PAGE_UPDATE, this.setup);
    window.addEventListener(NAVIGATE, this.destroy);
    window.addEventListener("popstate", this.destroy);
    this.setup();
  }

  setup() {
    this.upperEl = document.querySelector(".AboutCanvas.Upper");
    this.lowerEl = document.querySelector(".AboutCanvas.Lower");
    this.imageEl = document.querySelector(".AboutImage-img");
    if (this.imageEl) {
      if (this.imageEl.complete) {
        this.handleImageLoaded();
      } else {
        this.imageEl.addEventListener("load", this.handleImageLoaded);
      }
      window.addEventListener("mousemove", this.handleMouseMove);
    }
  }

  destroy() {
    window.removeEventListener("mousemove", this.handleMouseMove);
  }

  async handleImageLoaded() {
    this.drawImage(this.lowerEl);
    this.drawImage(this.upperEl);
    const textEl = document.querySelector(".AboutText");
    if (textEl) {
      await sleep(CONTENT_DELAY);
      textEl.style.display = "block";
      textEl.style.opacity = 1;
    }
  }

  drawImage(canvasEl) {
    const ctx = canvasEl.getContext("2d");
    ctx.drawImage(
      this.imageEl,
      0,
      0,
      this.imageEl.naturalWidth,
      this.imageEl.naturalHeight
    );
  }

  handleMouseMove(e) {
    const windowAspect = window.innerWidth / window.innerHeight;
    const imageAspect = this.imageEl.naturalWidth / this.imageEl.naturalHeight;

    let x, y, scale;

    if (windowAspect > imageAspect) {
      // Window aspect is more landscape than image, therefore object fit will
      // be matching width and chopping off the top and bottom of the image
      scale = this.imageEl.naturalWidth / window.innerWidth;
      x = e.clientX * scale;
      y =
        e.clientY * scale +
        (this.imageEl.naturalHeight - window.innerHeight * scale) / 2;
    } else {
      // Window aspect is more portrait than image, therefore object fit will
      // be matching height and chopping off the sides of the image
      scale = this.imageEl.naturalHeight / window.innerHeight;
      y = e.clientY * scale;
      x =
        e.clientX * scale +
        (this.imageEl.naturalWidth - window.innerWidth * scale) / 2;
    }

    const ctx = this.upperEl.getContext("2d");
    ctx.globalCompositeOperation = "destination-out";
    ctx.beginPath();
    ctx.lineCap = "round";
    ctx.moveTo(x, y);
    ctx.arc(x, y, 50, 0, 2 * Math.PI);
    ctx.fill();
  }
}
