import { PAGE_UPDATE } from "./Navigation";

export default class Archive {
  constructor() {
    this.setup = this.setup.bind(this);
    this.handleButtonClick = this.handleButtonClick.bind(this);
    window.addEventListener(PAGE_UPDATE, this.setup);
    this.setup();
  }
  setup() {
    this.archiveCardEls = Array.from(
      document.querySelectorAll(".js-ProjectCard.hidden")
    );
    this.buttonEl = document.querySelector(".js-ArchiveButton");

    if (this.buttonEl) {
      this.buttonEl.addEventListener("click", this.handleButtonClick);
    }
  }

  handleButtonClick() {
    this.buttonEl.classList.add("hidden");
    this.archiveCardEls.forEach((archiveCardEl) => {
      archiveCardEl.classList.remove("hidden");
    });
  }
}
