import { PAGE_UPDATE } from "./Navigation";

export const NAVIGATE = "NAVIGATE";
export const LINK_UPDATE = "LINK_UPDATE";

export default class Links {
  constructor() {
    this.setup = this.setup.bind(this);
    window.addEventListener(PAGE_UPDATE, this.setup);
    window.addEventListener(LINK_UPDATE, this.setup);
    this.setup();
  }

  setup() {
    this.els = Array.from(document.querySelectorAll("a"));
    this.els = this.els.filter((el) => {
      // Filter out any external or mailto links
      const href = el.getAttribute("href");
      const isExternal = href.indexOf("http") === 0;
      const isMailTo = href.indexOf("mailto") === 0;
      const isTel = href.indexOf("tel") === 0;
      return !isExternal && !isMailTo && !isTel;
    });
    this.els.forEach((el) => {
      el.addEventListener("click", this.handleLinkClick);
    });
  }

  handleLinkClick(e) {
    e.preventDefault();
    const el = e.currentTarget;
    const pathname = el.getAttribute("href");
    if (pathname && pathname != window.location.pathname) {
      const data = Object.assign({}, el.dataset);
      window.dispatchEvent(
        new CustomEvent(NAVIGATE, { detail: { pathname, data } })
      );
      window.history.pushState(
        {
          pathname,
          data,
        },
        null,
        pathname
      );
    }
  }
}
