import arrayShuffle from "array-shuffle";

import { PAGE_UPDATE } from "./Navigation";
import { SPLASH_COMPLETE, SPLASH_LOADED } from "./Splash";

export default class DirectorVideos {
  constructor(splash) {
    this.splash = splash;
    this.setup = this.setup.bind(this);
    this.begin = this.begin.bind(this);
    this.handleLoopVideoEnded = this.handleLoopVideoEnded.bind(this);
    this.handleVideoError = this.handleVideoError.bind(this);
    this.handleRollover = this.handleRollover.bind(this);
    this.handleRolloff = this.handleRolloff.bind(this);

    window.addEventListener(PAGE_UPDATE, this.setup);

    if (this.splash.waitForSplash) {
      // If the splash controller has an element, we've landed on the homepage and therefore
      // we need to wait for the splash to load its media before we do our setup, to prevent the video
      // loading competing with the splash image loading
      if (this.splash.loaded) {
        this.setup();
      } else {
        window.addEventListener(SPLASH_LOADED, this.setup);
      }
    } else {
      this.setup();
    }
  }

  setup() {
    this.directorNameEls = Array.from(
      document.querySelectorAll(".js-DirectorName")
    );

    this.directorListEl = document.querySelector(".DirectorList");

    const loopVideosContainer = document.querySelector(".LoopVideos");
    const rolloverVideosContainer = document.querySelector(".RolloverVideos");

    const childPageVideoUrlsEl = document.getElementById(
      "child-page-video-urls"
    );

    if (childPageVideoUrlsEl) {
      const videoUrls = JSON.parse(childPageVideoUrlsEl.textContent);
      const randomizedVideoUrls = arrayShuffle(videoUrls);

      // Points where every video in randomizedVideoUrls actually is in videoUrls.
      // Eg. this.randomToOriginal = [5, 7, 1, 9, 2, 8, 6, 4, 3, 0]
      // Means the URL in randomizedVideoUrls[0] can also be found in videoUrls[5]
      this.randomToOriginal = randomizedVideoUrls.map((videoUrl) => {
        return videoUrls.indexOf(videoUrl);
      });

      // Points where every video in videoUrls actually is in randomizedVideoUrls.
      // Eg. this.originalToRandom = [9, 2, 4, 8, 7, 0, 6, 1, 5, 3]
      // Means the URL in videoUrls[0] can also be found in videoUrls[9]
      this.originalToRandom = videoUrls.map((videoUrl) => {
        return randomizedVideoUrls.indexOf(videoUrl);
      });

      this.loopVideos = randomizedVideoUrls.map((videoUrl) => {
        const videoEl = document.createElement("video");
        videoEl.src = videoUrl;
        videoEl.muted = true;
        videoEl.setAttribute("playsinline", "true");
        videoEl.preload = true;
        videoEl.className = "LoopVideo";
        videoEl.addEventListener("ended", this.handleLoopVideoEnded);
        loopVideosContainer.appendChild(videoEl);
        return videoEl;
      });

      this.rolloverVideos = randomizedVideoUrls.map((videoUrl) => {
        const videoEl = document.createElement("video");
        videoEl.src = videoUrl;
        videoEl.muted = true;
        videoEl.playsinline = true;
        videoEl.preload = true;
        videoEl.className = "RolloverVideo";
        videoEl.loop = true;
        rolloverVideosContainer.appendChild(videoEl);
        return videoEl;
      });

      if (this.splash.waitForSplash) {
        // If the splash controller has an element, we've landed on the homepage and therefore
        // we need to wait for the splash to complete before showing the videos
        window.addEventListener(SPLASH_COMPLETE, this.begin);
      } else {
        this.begin();
      }
    }
  }

  begin() {
    if (this.directorNameEls.length) {
      this.directorNameEls.forEach((directorNameEl, index) => {
        setTimeout(() => {
          directorNameEl.classList.add("fadeIn");
        }, index * 200);
      });

      this.rolloverIndex = null;
      this.loopIndex = 0;

      const loopVideoEl = this.loopVideos[this.loopIndex];
      // We show the loops in a randomized order
      // And we need the corresponding director name to highlight
      // So we find the index of the current random video in the original array
      const mappedIndex = this.randomToOriginal[this.loopIndex];
      // And that index would match the index of the video's director's name in this.directorNameEls
      let directorNameEl = this.directorNameEls[mappedIndex];
      directorNameEl.classList.add("active");
      if (loopVideoEl) {
        const playPromise = loopVideoEl.play();
        if (playPromise !== undefined) {
          playPromise
            .then(() => {})
            .catch(() => {
              this.handleVideoError(loopVideoEl, directorNameEl.dataset.poster);
            });
        }
        loopVideoEl.classList.add("active");
      }

      if (!document.body.classList.contains("isTouch")) {
        this.directorNameEls.forEach((directorNameEl) => {
          directorNameEl.addEventListener("mouseenter", this.handleRollover);
          directorNameEl.addEventListener("mouseleave", this.handleRolloff);
        });
      }
    }
  }

  handleLoopVideoEnded(e) {
    const loopVideoEl = e.currentTarget;
    this.loopIndex = this.loopVideos.indexOf(loopVideoEl);
    const mappedIndex = this.randomToOriginal[this.loopIndex];
    let directorNameEl = this.directorNameEls[mappedIndex];
    loopVideoEl.currentTime = 0;
    if (this.loopIndex === this.originalToRandom[this.rolloverIndex]) {
      const playPromise = loopVideoEl.play();
      if (playPromise !== undefined) {
        playPromise.then(() => {}).catch(() => {});
      }
    } else {
      directorNameEl.classList.remove("active");
      loopVideoEl.classList.remove("active");
      // Going to the next loopEl in the randomized order
      this.loopIndex = this.loopIndex + 1;
      if (this.loopIndex >= this.loopVideos.length) this.loopIndex = 0;
      const mappedIndex = this.randomToOriginal[this.loopIndex];
      // We find what the next director's index is in the original array
      const nextDirectorEl = this.directorNameEls[mappedIndex];
      const nextVideoEl = this.loopVideos[this.loopIndex];
      nextVideoEl.classList.add("active");
      try {
        const playPromise = nextVideoEl.play();
        if (playPromise !== undefined) {
          playPromise
            .then(() => {})
            .catch(() => {
              this.handleVideoError(nextVideoEl, nextDirectorEl.dataset.poster);
            });
        }
      } catch {
        //
      }
      nextDirectorEl.classList.add("active");
    }
  }

  handleRollover(e) {
    const directorNameEl = e.currentTarget;
    this.rolloverIndex = this.directorNameEls.indexOf(directorNameEl);
    const mappedIndex = this.originalToRandom[this.rolloverIndex];
    if (mappedIndex !== this.loopIndex) {
      const rolloverVideoEl = this.rolloverVideos[mappedIndex];
      rolloverVideoEl.currentTime = 0;
      rolloverVideoEl.classList.add("active");
      // https://developer.chrome.com/blog/play-request-was-interrupted/#how-to-fix-it
      // This doesn't do anything, it just catches the console error...
      // "The play() request was interrupted because the media was removed from the document."
      const playPromise = rolloverVideoEl.play();
      if (playPromise !== undefined) {
        playPromise.then(() => {}).catch(() => {});
      }
    }

    this.directorListEl.classList.add("interactive");
  }

  handleRolloff(e) {
    this.rolloverIndex = null;
    const directorNameEl = e.currentTarget;
    const rolloffIndex = this.directorNameEls.indexOf(directorNameEl);
    // We rolloff off the director's name which is in the original order
    // We need to find its video in the randomized array to stop it looping
    const mappedIndex = this.originalToRandom[rolloffIndex];
    const rolloverVideoEl = this.rolloverVideos[mappedIndex];
    rolloverVideoEl.classList.remove("active");
    this.directorListEl.classList.remove("interactive");
  }

  handleVideoError(videoEl, posterSrc) {
    videoEl.classList.add("fadeIn");
    videoEl.style.backgroundImage = `url('${posterSrc}')`;
    videoEl.style.backgroundRepeat = "no-repeat";
    videoEl.style.backgroundPosition = "center center";
    videoEl.style.backgroundSize = "cover";
    setTimeout(() => {
      videoEl.classList.remove("fadeIn");
      videoEl.dispatchEvent(new Event("ended"));
    }, 5000);
  }
}
