import { PAGE_UPDATE } from "./Navigation";

export default class Burger {
  constructor() {
    this.setup = this.setup.bind(this);
    window.addEventListener(PAGE_UPDATE, this.setup);
    window.addEventListener("resize", this.handleResize);
    this.setup();
  }
  setup() {
    this.el = document.querySelector(".js-Burger");
    const activeListEl = document.querySelector(".js-MobileMenuItem.active");
    if (this.el) {
      this.el.addEventListener("click", this.handleClick);

      if (activeListEl) {
        activeListEl.addEventListener("click", this.handleClick);
      }
    }
  }
  handleClick() {
    document.body.classList.toggle("menuOpen");
  }

  handleResize() {
    const isMenuOpen = document.body.classList.contains("menuOpen");
    if (window.innerWidth > 960 && isMenuOpen) {
      document.body.classList.remove("menuOpen");
    }
  }
}
