import { forceReflow, sleep } from "../modules/utils";
import { NAVIGATE } from "./Links";

export const ADD_PROJECT_OVERLAY = "ADD_PROJECT_OVERLAY";
export const FULL_REPLACE = "FULL_REPLACE";
export const PAGE_UPDATE = "PAGE_UPDATE";
export const REMOVE_PROJECT_OVERLAY = "REMOVE_PROJECT_OVERLAY";
export const CHANGE_PROJECT_OVERLAY = "CHANGE_PROJECT_OVERLAY";

export default class Navigation {
  constructor() {
    this.cache = { [this.initialPathname]: document.documentElement.outerHTML };
    document.body.classList.add("active");
    this.initialPathname = window.location.pathname;

    this.handleNavigate = this.handleNavigate.bind(this);
    this.loadContent = this.loadContent.bind(this);
    window.addEventListener(NAVIGATE, this.handleNavigate);
    window.addEventListener("popstate", (event) => {
      if (event.state === null) {
        this.gotoPage(this.initialPathname);
      } else {
        this.gotoPage(event.state.pathname, event.state.data);
      }
    });
  }

  handleNavigate(e) {
    const { pathname, data } = e.detail;
    this.gotoPage(pathname, data);
  }

  async gotoPage(pathname, data) {
    let updateType = FULL_REPLACE;

    if (data) {
      const { pageType, parent } = data;
      if (pageType && parent) {
        const outgoingPageType = document.body.dataset.pageType;
        const outgoingParent = document.body.dataset.parent;
        const incomingPageType = pageType;
        const incomingParent = parent;
        if (incomingParent === outgoingParent) {
          const parentToProject =
            (outgoingPageType === "DirectorPage" ||
              outgoingPageType === "TreatmentPage") &&
            incomingPageType === "ProjectPage";
          const projectToParent =
            outgoingPageType === "ProjectPage" &&
            (incomingPageType === "DirectorPage" ||
              incomingPageType === "TreatmentPage");
          if (parentToProject) {
            updateType = ADD_PROJECT_OVERLAY;
          } else if (projectToParent) {
            updateType = REMOVE_PROJECT_OVERLAY;
          } else {
            updateType = CHANGE_PROJECT_OVERLAY;
          }
        }
      }
    }

    let _document;
    if (updateType === FULL_REPLACE) {
      const transitionTime = 250;
      document.body.classList.remove("active");
      _document = await Promise.all([
        this.loadContent(pathname),
        await sleep(transitionTime),
      ]).then((values) => {
        return values[0];
      });
    } else {
      _document = await this.loadContent(pathname);
    }
    if (updateType === FULL_REPLACE) {
      const _body = _document.getElementsByTagName("body")[0];
      document.body.replaceWith(_body);
      window.scrollTo(0, 0);
      forceReflow(document.body);
      document.body.classList.add("active");
    } else {
      document.body.dataset.pageType = _document.body.dataset.pageType;
      if (updateType === ADD_PROJECT_OVERLAY) {
        window.dispatchEvent(
          new CustomEvent(ADD_PROJECT_OVERLAY, {
            detail: { document: _document },
          })
        );
      } else if (updateType === REMOVE_PROJECT_OVERLAY) {
        window.dispatchEvent(new Event(REMOVE_PROJECT_OVERLAY));
      } else {
        window.dispatchEvent(
          new CustomEvent(CHANGE_PROJECT_OVERLAY, {
            detail: { document: _document },
          })
        );
      }
    }
    window.dispatchEvent(new Event(PAGE_UPDATE));
  }

  async loadContent(pathname) {
    let text;
    if (pathname in this.cache) {
      console.log("From cache");
      text = this.cache[pathname];
    } else {
      console.log("Not From cache");
      const headers = {
        "X-Requested-With": "XMLHttpRequest",
      };
      const response = await fetch(pathname, headers);
      text = await response.text();
      this.cache[pathname] = text;
    }

    const domParser = new DOMParser();
    const parsedDocument = domParser.parseFromString(text, "text/html");
    this.setTitle(parsedDocument);
    return parsedDocument;
  }

  setTitle(_document) {
    let title;
    try {
      title = _document.getElementsByTagName("title")[0].innerText;
    } catch {
      title = "";
    }
    document.title = title;
  }
}
