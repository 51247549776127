// eslint-disable-next-line
import * as Sentry from "@sentry/browser";
import { BrowserTracing } from "@sentry/tracing";

export default class Burger {
  constructor() {
    if (process.env.NODE_ENV !== "development") {
      try {
        Sentry.init({
          dsn: window.bridge.SENTRY_DSN,
          release: window.bridge.VERSION,
          environment: window.bridge.ENV,
          integrations: [new BrowserTracing()],
          tracesSampleRate: 0.1,
          allowUrls: "ansor",
        });
      } catch (error) {
        console.error("Error setting up Sentry");
      }
    }
  }
}
