import { PAGE_UPDATE } from "./Navigation";

export default class MobileMenu {
  constructor() {
    this.setup = this.setup.bind(this);
    window.addEventListener(PAGE_UPDATE, this.setup);
    window.addEventListener("resize", this.setup);
    this.setup();
  }
  setup() {
    const mobileMenuWrapperEl = document.querySelector(".MobileMenu-wrapper");
    if (mobileMenuWrapperEl)
      mobileMenuWrapperEl.style.height = window.innerHeight;
  }
}
