export const SPLASH_COMPLETE = "SPLASH_COMPLETE";
export const SPLASH_LOADED = "SPLASH_LOADED";

export default class Splash {
  constructor() {
    this.handleImageLoaded = this.handleImageLoaded.bind(this);
    this.handleAnimationComplete = this.handleAnimationComplete.bind(this);
    this.el = document.querySelector(".Splash");
    this.loaded = false;
    if (this.el) {
      this.waitForSplash = true;
      const imageEl = this.el.querySelector(".Splash-img");
      if (imageEl) {
        this.el.classList.add("visible");
        if (imageEl.complete) {
          this.handleImageLoaded();
        } else {
          imageEl.addEventListener("load", this.handleImageLoaded);
        }
      }
    }
  }

  handleImageLoaded() {
    if (this.el) {
      this.loaded = true;
      window.dispatchEvent(new Event(SPLASH_LOADED));
      this.el.classList.add("loaded");
      setTimeout(() => {
        this.waitForSplash = false;
        this.handleAnimationComplete();
      }, 4_000);
    }
  }

  handleAnimationComplete() {
    if (this.el) {
      this.el.classList.add("complete");
      window.dispatchEvent(new Event(SPLASH_COMPLETE));
    }
  }
}
