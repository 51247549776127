import { PAGE_UPDATE, REMOVE_PROJECT_OVERLAY } from "./Navigation";

export default class ProjectGrid {
  constructor() {
    this.setup = this.setup.bind(this);
    window.addEventListener(PAGE_UPDATE, this.setup);
    this.handleRemoveOverlay = this.handleRemoveOverlay.bind(this);
    window.addEventListener(REMOVE_PROJECT_OVERLAY, this.handleRemoveOverlay);
    this.setup();
  }
  setup() {
    this.els = Array.from(document.querySelectorAll(".js-ProjectCard"));
    this.els.forEach((el) => {
      el.addEventListener("click", this.handleItemClick);
    });
  }
  handleItemClick(e) {
    e.preventDefault();
    e.target.classList.add("active");
  }
  handleRemoveOverlay() {
    this.els.forEach((el) => {
      el.classList.remove("active");
    });
  }
}
