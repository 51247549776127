export default class DetectTouchScreen {
  constructor() {
    this.setup = this.setup.bind(this);
    this.setup();
  }
  setup() {
    const ua = window.navigator.userAgent;

    const isMobile = ua.match(
      /(iPhone|iPod|iPad|Android|webOS|BlackBerry|IEMobile|Opera Mini)/i
    );

    if ("ontouchstart" in document.documentElement && isMobile) {
      document.body.classList.add("isTouch");
    }
  }
}
