import "../scss/index.scss";
import Archive from "./components/Archive.js";
import Burger from "./components/Burger.js";
import DetectTouchScreen from "./components/DetectTouchScreen.js";
import DirectorVideos from "./components/DirectorVideos.js";
import FadeUpOnLoad from "./components/FadeUpOnLoad.js";
import InfiniteScroll from "./components/InfiniteScroll.js";
import Links from "./components/Links";
import MobileMenu from "./components/MobileMenu.js";
import Navigation from "./components/Navigation.js";
import PhotographyLightbox from "./components/PhotographyLightbox.js";
import ProjectGrid from "./components/ProjectGrid";
import ProjectOverlay from "./components/ProjectOverlay";
import Sentry from "./components/Sentry.js";
import Splash from "./components/Splash.js";
import TextReveal from "./components/TextReveal.js";

console.log(`Imperial Woodpecker ${window.bridge.VERSION}`);

window.addEventListener("DOMContentLoaded", () => {
  new Archive();
  new Burger();
  new DetectTouchScreen();
  new FadeUpOnLoad();
  new Links();
  new MobileMenu();
  new Navigation();
  new InfiniteScroll();
  new PhotographyLightbox();
  new ProjectGrid();
  new ProjectOverlay();
  new Sentry();
  new TextReveal();
  const splash = new Splash();
  new DirectorVideos(splash);
});
