import { forceReflow, sleep } from "../modules/utils";
import { LINK_UPDATE } from "./Links";
import {
  ADD_PROJECT_OVERLAY,
  CHANGE_PROJECT_OVERLAY,
  PAGE_UPDATE,
  REMOVE_PROJECT_OVERLAY,
} from "./Navigation";

const CONTENT_DELAY = 250;

export default class ProjectOverlay {
  constructor() {
    this.setup = this.setup.bind(this);
    this.addContent = this.addContent.bind(this);
    window.addEventListener(PAGE_UPDATE, this.setup);
    this.handleAddOverlay = this.handleAddOverlay.bind(this);
    window.addEventListener(ADD_PROJECT_OVERLAY, this.handleAddOverlay);
    this.handleRemoveOverlay = this.handleRemoveOverlay.bind(this);
    window.addEventListener(REMOVE_PROJECT_OVERLAY, this.handleRemoveOverlay);
    this.handleChangeOverlay = this.handleChangeOverlay.bind(this);
    window.addEventListener(CHANGE_PROJECT_OVERLAY, this.handleChangeOverlay);
    this.setup();
  }

  async setup() {
    this.el = document.querySelector(".js-ProjectOverlay");
    await sleep(CONTENT_DELAY);
    this.addContent();
  }

  async handleAddOverlay(e) {
    if (this.el) {
      const incomingEl = e.detail.document.querySelector(".js-ProjectOverlay");
      incomingEl.classList.remove("active");
      this.el.replaceWith(incomingEl);
      this.setup();
      forceReflow(this.el);
      this.el.classList.add("active");
      await sleep(CONTENT_DELAY);
      this.addContent();
      await sleep(CONTENT_DELAY);
      const createdVideoEl = this.el.querySelector(".EmbedWrapper video");
      if (createdVideoEl) {
        createdVideoEl.play();
      }
    }
    window.addEventListener("keyup", this.handleKeyUp);
  }

  addContent() {
    if (this.el) {
      const embedWrapperEl = this.el.querySelector(".EmbedWrapper");
      if (embedWrapperEl) {
        embedWrapperEl.innerHTML = embedWrapperEl.dataset.content;
      }
    }
  }

  handleKeyUp(e) {
    const overlayEl = document.querySelector(".js-ProjectOverlay");
    if (e.key === "ArrowRight") {
      const nextButtonEl = overlayEl.querySelector(
        ".ProjectOverlay-navButton.next"
      );
      nextButtonEl.click();
    } else if (e.key === "ArrowLeft") {
      const prevButtonEl = overlayEl.querySelector(
        ".ProjectOverlay-navButton.prev"
      );
      prevButtonEl.click();
    }
  }

  handleRemoveOverlay() {
    if (this.el) {
      const iframeEl = this.el.querySelector(".ProjectOverlay-info iframe");
      if (iframeEl) {
        iframeEl.src = "about:blank";
      }
      this.el.classList.remove("active");
      window.removeEventListener("keyup", this.handleKeyUp);
    }
  }

  async handleChangeOverlay(e) {
    let contentEl = this.el.querySelector(".js-ProjectOverlayContent");
    const prevButtonEl = this.el.querySelector(
      ".ProjectOverlay-navButton.prev"
    );
    const nextButtonEl = this.el.querySelector(
      ".ProjectOverlay-navButton.next"
    );

    contentEl.classList.remove("active");
    await sleep(CONTENT_DELAY);
    const incomingEl = e.detail.document.querySelector(
      ".js-ProjectOverlayContent"
    );
    const incomingPrevButtonEl = e.detail.document.querySelector(
      ".ProjectOverlay-navButton.prev"
    );
    const incomingNextButtonEl = e.detail.document.querySelector(
      ".ProjectOverlay-navButton.next"
    );
    incomingEl.classList.remove("active");
    contentEl.replaceWith(incomingEl);
    prevButtonEl.replaceWith(incomingPrevButtonEl);
    nextButtonEl.replaceWith(incomingNextButtonEl);
    contentEl = this.el.querySelector(".js-ProjectOverlayContent");
    forceReflow(contentEl);
    contentEl.classList.add("active");
    window.dispatchEvent(new Event(LINK_UPDATE));
  }
}
